import styled from '@emotion/styled'
import Link from 'next/link'

import { breakpoint } from '@/theme'
import { TEST_IDS } from '@components/city-page/test/constants'
import useFooterRules from '@components/footer/useFooterRules'
import Logo from '@microcomponents/icons/logo'

export default function Footer() {
  const getCurrentYr = () => new Date().getFullYear()
  const footerWarningMessages = useFooterRules()

  return (
    <Container>
      <Link href="/" passHref>
        <LogoLink>
          <Logo fill="#fff" />
        </LogoLink>
      </Link>
      <Content>
        <Section>
          <Copy>Copyright © 2014-{getCurrentYr()} Eaze Technologies, Inc. All rights reserved.</Copy>
          {footerWarningMessages &&
            footerWarningMessages.map((warningObj, index) => (
              <Copy key={index} data-test={`warningMsg-${index}`}>
                {warningObj.message}
              </Copy>
            ))}
        </Section>
        <Section>
          <Link href="/signup" passHref>
            <FooterLink>Sign Up</FooterLink>
          </Link>
          <Link href="/login" passHref>
            <FooterLink>Log In</FooterLink>
          </Link>
          <Link href="https://help.eaze.com/" passHref>
            <FooterLink target="_blank" rel="noopener noreferrer">
              Support
            </FooterLink>
          </Link>
          <Link href="/contact" passHref>
            <FooterLink>Contact Us</FooterLink>
          </Link>
        </Section>
        <Section>
          <Link href="/about" passHref>
            <FooterLink>About Us</FooterLink>
          </Link>
          <Link href="/blog" passHref>
            <FooterLink>Blog</FooterLink>
          </Link>
          <Link href="/press" passHref>
            <FooterLink>Press</FooterLink>
          </Link>
          <Link href="/careers" passHref>
            <FooterLink>Careers</FooterLink>
          </Link>
        </Section>
        <Section>
          <Link data-e2eid={TEST_IDS.CITY_PAGE_FOOTER_LINK} href="/weed-delivery" passHref>
            <FooterLink>Delivery Locations</FooterLink>
          </Link>
          <Link href="https://drive.eaze.com" passHref>
            <FooterLink target="_blank" rel="noopener noreferrer">
              Become a Driver
            </FooterLink>
          </Link>
          <Link href="/licensed-retailers" passHref>
            <FooterLink>Licensed Retailers</FooterLink>
          </Link>
          <Link href="/referral-program-rules" passHref>
            <FooterLink>Referral Program Rules</FooterLink>
          </Link>
        </Section>
        <Section>
          <Link href="/terms-of-service" passHref>
            <FooterLink>Terms of Service</FooterLink>
          </Link>
          <Link href="/privacy-policy" passHref>
            <FooterLink>Privacy Policy</FooterLink>
          </Link>
          <Link href="/cookie-manager" passHref>
            <FooterLink>Do Not Sell My Info</FooterLink>
          </Link>
        </Section>
      </Content>
    </Container>
  )
}

const cols = '5'

const Container = styled.footer`
  background-color: #353034;
  padding: 5rem 5rem 2rem;

  @media (max-width: ${breakpoint.max.md}px) {
    padding: 2rem 2rem 9rem;
  }
`

const Content = styled.div`
  max-width: ${breakpoint.min.xxl}px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(${cols}, 1fr);
  grid-column-gap: 4rem;
  align-items: start;

  & a,
  & a:link,
  & a:visited,
  & a:active {
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.typography.font.sim};
    text-decoration: none;
  }

  @media (max-width: ${breakpoint.max.md}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const LogoLink = styled.a`
  display: block;
  max-width: ${breakpoint.min.xxl}px;
  margin: 0 auto ${({ theme }) => theme.spacing.large}px;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakpoint.max.md}px) {
    margin: 0;

    &:first-of-type {
      margin-bottom: ${({ theme }) => theme.spacing.xxl}px;
    }
  }
`

const FooterLink = styled.a`
  text-transform: lowercase;
  margin-bottom: 2rem;

  @media (max-width: ${breakpoint.max.md}px) {
    margin-bottom: 1rem;
  }
`

const Copy = styled.p`
  flex: 1.5 0;
  font-family: ${({ theme }) => theme.typography.font.sim};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.textDisabled};
  margin: 0;
`
