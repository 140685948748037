import { testBaseProduct } from '@components/article/test/constants'

export const TEST_IDS = {
  CITY_PAGE_HEADER: 'city page header',
  CITY_PAGE_GROUPS: 'city page groups',
  CITY_PAGE_BRANDS: 'city page brands',
  CITY_PAGE_NEAREST_CITIES: 'city page nearest cities',
  CITY_PAGE_FOOTER_LINK: 'city page footer link'
}

export const testProductCount = 600
export const testProduct = {
  brand: {
    id: '4d7dc298-55eb-44ce-a251-c247a21458e4',
    name: 'LEVEL',
    description: 'LEVEL is a cannabinoid company that facilities cus…ed to embark on your individual cannabis journey.',
    slug: 'level'
  },
  catalogItemId: 'ab7ea76c-605a-439e-b7eb-3f05fdbdaf1c',
  cbd: 3,
  cbdMeasure: 'mg',
  cogs: null,
  description:
    'This product is high-potency and recommended for experienced consumers. LEVEL’s Hybrid ProTab is an orally consumed concentrate tablet that contains 25 mg THC per tablet. Extra strength and designed to pack a cannabinoid punch, The HYBRID PROTAB is infused with steam distilled hybrid terpenes to provide a classic high balanced in body and mind! Swallow with liquid. The tablets are scored so that you can break in half if you prefer to start with 12.5 mg. 10 ProTabs per package, 250 mg total. Ingredients: Microcrystalline Cellulose, delta-9 tetrahydrocannabinol, Croscarmellose, silicon dioxide, magnesium stearate, steam distilled cannabis terpenoids. Vegan and gluten free! (License No. CDPH-10004472)',
  effects: [],
  excise_tax_exempt: false,
  highCBD: false,
  id: 20484,
  isAccessory: false,
  isAvailable: true,
  isShown: true,
  maxQuantity: 10,
  name: 'Hybrid ProTab',
  photoUrl:
    'https://images.eazeup.com/images/eaze-user-files-production-public/72a6567d-5945-4167-8fe8-e0e358c5274c_Level_Protab_Hybrid_Menu.jpg',
  price: 26,
  rating: 0,
  slug: 'hybrid-protab',
  species: {
    id: 'e13588cd-f419-4526-aca3-01073dc29355',
    name: 'Hybrid',
    description: null,
    slug: 'hybrid',
    highCBD: true,
    isAccessory: false
  },
  strain: {
    id: '2ea69911-da4d-432d-9029-7f4f58da6e73',
    name: 'No Strain',
    description: null,
    slug: 'no-strain',
    highCBD: false
  },
  subtype: {
    id: '953e932c-e05c-46b8-842d-430007f8450c',
    name: 'Capsules',
    description: '',
    slug: 'capsules-na',
    highCBD: true
  },
  tag: null,
  thc: 250,
  thcMeasure: 'mg',
  type: { id: '5cc9ff17-f300-490a-ac13-a750af7325ff', name: 'Edibles', description: null, slug: 'edibles' },
  weight: 0.25
}

export const testCityPage = {
  location: {
    city: 'San Francisco',
    zipcode: '94110',
    region: 'San Francisco County',
    state_short: 'CA',
    state_long: 'California'
  },
  article_img: false,
  collection_img: false,
  collection_img_thumb: false,
  content: '',
  groups: [{ id: 'test_id', products: [testBaseProduct], slug: 'test', name: 'test group name', totalProducts: 1 }],
  title: 'Weed Delivery In San Francisco, CA',
  slug: 'weed-delivery-in-san-francisco-ca'
}

export const testBrandLogo = {
  alt: 'humboldt',
  author: '64',
  caption: 'humbold logo',
  description: 'humbold logo',
  height: 108,
  id: 12620,
  name: 'hf_logo_final-1-2',
  title: 'HF_Logo_Final 1',
  url: 'https://cms-assets.eaze.com/content/2022/04/26201051/HF_Logo_Final-1-1.jpg',
  width: 109
}
